import React from 'react'
import {Paper, Container, Avatar, Grid, Typography, Box} from '@material-ui/core';
import PeopleCentricIcon from '../../images/blix-people-centric-icon.svg'
import GroupPeopleIcon from '../../images/blix-Group-people-icon.svg'
import InboxIcon from '../../images/blix-inbox-icon.svg'

const featuresSections = [
    {
        title: 'Everything you need to boost your work productivity',
        image: "/images/features-page/Blix-Chat-Right-Pane.webp",

        items: [
            {
                icon: <PeopleCentricIcon/>,
                title: 'Share Mail with Team',
                subtext: 'Got an interesting email in your inbox? Blix new workflow enables you to simply share an email with your workgroup, so that all members of the team can view and comment on it in real-time.'
            },
            {
                icon: <GroupPeopleIcon/>,
                title: 'Team Mail',
                subtext: 'For bringing people together, Team Mail enables you to send emails to a team without adding each member individually. You can then start chatting with the same team, to follow on. You can share messages, photos, and videos with up to 256 people at once.'
            },
            {
                icon: <InboxIcon/>,
                title: 'One Messaging App',
                subtext: 'Streamline your organizational messaging, with a single tap away to switch between your email and chat, interacting via teams and direct messages in real-time.'
            },
        ]
    },
]

const FeaturesFirstSections = ({imagesPrefix}) => {
    return (
        featuresSections.map((featureSection, i) => {
            return (

                <Paper key={i} className='first-features-section'>
                    <Container  maxWidth={false} className="container">
                    <Typography variant="h3" className="title">{featureSection.title}</Typography>
                        <Grid container direction="row" justify="center" alignItems="center" style={{alignItems: 'center'}} spacing={10}>
                            <Box clone order={{xs: 2, sm: 2, md: 1, lg: 1}}>
                                <Grid item xs={12} sm={12} md={6} lg={6} style={{paddingBottom: '50px', maxWidth: '580px'}}>
                                        {featureSection.items.map((item, i) => {
                                            return (
                                                // <Container maxWidth='sm' key={i} style={{float: 'left'}}>
                                                    <Grid key={i} container direction='row' justify="flex-start" alignItems="center" className="grid-items">
                                                        <Grid className="avatar-grid">
                                                            <Avatar className="avatar">
                                                                {item.icon}
                                                            </Avatar>
                                                        </Grid>
                                                        <Typography color='textPrimary' className="list-item-title">{item.title}</Typography>
                                                        <Typography  className="list-item-subtext">{item.subtext}</Typography>
                                                    </Grid>
                                                // </Container>
                                            );
                                        })}
                                </Grid>
                            </Box>
                            <Box clone order={{xs: 1, sm: 1, md: 2, lg: 2}}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <img src={imagesPrefix + featureSection.image} alt="blix Desktop email Windows"/>
                                </Grid>
                            </Box>
                        </Grid>
                    </Container>
                </Paper>
            );
        })
    );
}

export default FeaturesFirstSections

