import React from "react"
import SEO from '../components/seo';
import Layout from '../components/layout';
import { Paper} from '@material-ui/core';
import FeaturesFirstSections from '../components/features-page/blix-features-first-section'
import FeaturesChatSection from '../components/features-page/features-chat-section'
import FeaturesEmailSection from '../components/features-page/features-email-section'
// import FeaturessecondSection from '../components/features-page/blix-features-second-section'
import FeaturesThirdSection from '../components/features-page/blix-features-third-section'
import DownloadsSection from '../components/downloads-section'
import { graphql, StaticQuery } from 'gatsby'

const Features = ({location}) => {
    return (
        <StaticQuery
        query={graphql`
        query FeaturesPageQuery {
          site {
            siteMetadata {
              title
              env
              imagesPrefix
            }
          }
        }
        `}
        render={data => (
          <Layout location={location}>
            <div className="features-wrapper">
              <SEO title='Features' description='blix: boost your work, switch between email and chat without leaving Blix, Receive emails directly inside your chat conversation'/>
              <FeaturesFirstSections imagesPrefix={data.site.siteMetadata.imagesPrefix} />
              <FeaturesChatSection imagesPrefix={data.site.siteMetadata.imagesPrefix} />
              <FeaturesEmailSection imagesPrefix={data.site.siteMetadata.imagesPrefix} />
              {/* <FeaturessecondSection  imagesPrefix={data.site.siteMetadata.imagesPrefix}/> */}
              <FeaturesThirdSection/>
              <Paper style={{background: '#F0F4F5', paddingTop: '85px', paddingBottom: '117px', borderRadius: '0'}}>
                  <DownloadsSection data="Download Blix today!" direction="row"/>
              </Paper>
            </div>
        </Layout>
        )}
        />
    );
}

export default Features;