import React from 'react'
import {Container, Typography, Paper} from '@material-ui/core';
import iMailBridge from '../../images/hp-features/Blix-iMail-Bridge.mp4'
import ReactPlayer from 'react-player'

const pageData = [
    {
        title: 'Messaging Bridge',
        subtitle: 'The Messaging Bridge is a powerful capability which allows your organization to communicate with any visitor directly to your Blix chat teams. Each team can be designated with a different mailbox address. When a message is received you can then exchange comments internally with your teammates before replying to the sender by email. Visitors can send and receive emails in incognito mode without revealing their real email address.',
       image: <ReactPlayer url={iMailBridge} playsinline muted loop={true} volume={0} playing={true} />
    }
]

const FeaturesThirdSection = () => {
    return (
        pageData.map((data, i) => {
            return (
                <Paper key={i} className="third-features-section">
                    <Container fixed>
                        <Typography variant="h4" align='center' className="title">{data.title}</Typography>
                        <Typography variant="h6" align='center' className="subtitle">{data.subtitle}</Typography>
                        <div className="figure">{data.image}</div>
                    </Container>
                </Paper>
            );
        })
    );
}

export default FeaturesThirdSection