import React from 'react'
import {Typography, Container, Grid, Button } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const data = {
    title: 'Email',
    subtext: 'Keep in touch with the groups of people that matter the most, like your family or coworkers. You can also name your group, mute or customize notifications, and more.'
}

const desktop = [
    {
        name: 'People Centric',
        image: 0
    },
    {
        name: 'Dark Mode',
        image: 2
    },
    {
        name: 'Clusters',
        image: 1
    },
    
    {
        name: 'Unified Folders',
        image: 3
    }
]

const mobile = [
    {
        name: 'People Centric',
        image: 0
    },
    {
        name: 'Dark Mode',
        image: 1
    },
    {
        name: 'Clusters',
        image: 2
    },
    {
        name: 'Unified Folders',
        image: 3
    }
]
    
class FeaturesEmailSection extends React.Component{
    state = {
        currentButtonPlatform: 0,
        currentPlatform: desktop[0].image,
        activeIndex: 0,
    };

    desktopCarousel(){
        let {imagesPrefix} = this.props;
        return(
            <div>
            <Carousel className="desktop" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={false} showIndicators={false} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                <div >
                    <img src={`${imagesPrefix}/images/features-page/Blix-Mail-People-Centric.webp`} alt="blix email on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Blix-Dark Mode.webp`} alt="blix chat calendar on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Blix-Mail-Clusters.webp`} alt="blix chat on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Blix-Mail-Unified-Folders.webp`} alt="blix chat contacts on windows"/>
                </div>
            </Carousel>
            <Carousel className="desktop-for-mobile-screen" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={true} showIndicators={true} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                <div >
                    <img src={`${imagesPrefix}/images/features-page/Blix-Mail-People-Centric.webp`} alt="blix email on windows"/>
                    <p style={{color: '#000', marginTop: '15px', fontWeight: '500'}}>People Centric</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Blix-Dark Mode.webp`} alt="blix chat calendar on windows"/>
                    <p style={{color: '#000', marginTop: '15px', fontWeight: '500'}}>Dark Mode</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Blix-Mail-Clusters.webp`} alt="blix chat on windows"/>
                    <p style={{color: '#000', marginTop: '15px', fontWeight: '500'}}>Clusters</p>
                </div>
                
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Blix-Mail-Unified-Folders.webp`} alt="blix chat contacts on windows"/>
                    <p style={{color: '#000', marginTop: '15px', fontWeight: '500'}}>Unified Folders</p>
                </div>
            </Carousel>
            </div>
        );
    }
    mobileCarousel(){
        let {imagesPrefix} = this.props;
        return(
            <div>
            <Carousel className="mobile" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={false} showIndicators={false} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                <div >
                    <img src={`${imagesPrefix}/images/features-page/People-Centric.webp`}  alt="blix email on windows"/>
                </div>
                
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Dark Mode.webp`}  alt="blix chat calendar on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Clusters.webp`}  alt="blix chat on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Unified Folders.webp`}  alt="blix chat contacts on windows"/>
                </div>
            </Carousel>
            <Carousel className="mobile-for-mobile-screen" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={true} showIndicators={true} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                <div >
                    <img src={`${imagesPrefix}/images/features-page/People-Centric.webp`}  alt="blix email on windows"/>
                    <p style={{color: '#000'}}>People Centric</p>
                </div>
                
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Dark Mode.webp`}  alt="blix chat calendar on windows"/>
                    <p style={{color: '#000'}}>Dark Mode</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Clusters.webp`}  alt="blix chat on windows"/>
                    <p style={{color: '#000'}}>Clusters</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/images/features-page/Unified Folders.webp`}  alt="blix chat contacts on windows"/>
                    <p style={{color: '#000'}}>Unified Folders</p>
                </div>
            </Carousel>
            </div>
        );
    }
    async toggleClass(index){
        await this.setState({activeIndex: index})
    };

    render(){
        return (
            <div className="email-features-section">
                <Container fixed>
                    <Typography color='textPrimary' align="center" style={{fontSize: '38px', fontWeight: 'bold'}}>{data.title}</Typography>
                    <Typography color="textPrimary" align="center" variant="h6" style={{maxWidth: '1100px', margin: 'auto'}}>{data.subtext}</Typography>
                    <div className="buttons-wrapper" >
                        <button className={this.state.currentButtonPlatform === 0 ? 'active' : ''} onClick={async () => {await this.setState({currentButtonPlatform: 0}); await this.setState({currentPlatform: desktop})}}>Desktop</button>
                        <button className={this.state.currentButtonPlatform === 1 ? 'active' : ''} onClick={async() => {await this.setState({currentButtonPlatform: 1}); await this.setState({currentPlatform: mobile})}}>Mobile</button>
                        {/* <button className={'active'} onClick={async() => {await this.setState({currentButtonPlatform: 1}); await this.setState({currentPlatform: mobile})}}>Mobile</button> */}
                    </div>
                    <Grid container direction="row" justify="flex-end" alignItems="center" style={{marginTop: '10px'}} spacing={5}>
                        <Grid item md={3} sm={12} xs={12} className="side-btn-wrapper" style={{textAlign: 'center', display: 'grid'}}>
                            {desktop.map((item, i) => {
                                return(
                                    <Button key={i} className={this.state.activeIndex === i ? 'active' : ''} color="primary" onClick={() => {this.toggleClass(i)}}>{item.name}</Button>
                                );
                            })}
                        </Grid>
                        <Grid item md={9} sm={12} xs={12} className="grid-crousel-wrapper">
                            {/* <Carousel className="carousel-wrapper" showThumbs={false} showIndicators={false} showStatus={false}> */}
                                    <div>
                                    {this.state.currentButtonPlatform === 0 ? this.desktopCarousel() : 
                                    <div>{this.mobileCarousel()}</div>}
                                        {/* <div>  */}
                                        {/* <img src="/blix-Desktop-Chat-Calendar-Windows.png" alt="blix - macbook pro"/> */}
                                        {/* <div style={{position: 'absolute', width: '66.6%', left: '18%', top: '4.7%'}}>{this.mobileCarousel()}</div> */}
                                        {/* </div>} */}
                                        
                                    
                                    {/* <div style={{position: 'absolute', width: '66.6%', left: '18%', top: '4.7%'}}>{this.carouselimages()}</div> */}
                                        
                                    </div>
                            {/* </Carousel> */}
                        </Grid>
                        
                    </Grid>
                </Container>
            </div>
        );
    }
    
}

export default FeaturesEmailSection;