import React from 'react'
import {Typography, Container, Grid, Button } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const data = {
    title: 'Chat',
    subtext: 'Keep in touch with the teams of people that matter the most, like your family or coworkers. You can also name your group, mute or customize notifications, and more.'
}

const desktop = [
    {
        name: 'Create teams and channels',
        image: 0
    },
    {
        name: 'Email your chat team directly',
        image: 1
    },
    {
        name: 'Send/receive rich chat messages',
        image: 2
    },
    {
        name: 'Share mail with chat team',
        image: 3
    }
]

const mobile = [
    {
        name: 'Create teams and channels',
        image: 0
    },
    {
        name: 'Email your chat team directly',
        image: 1
    },
    {
        name: 'Send/receive rich chat messages',
        image: 2
    },
    {
        name: 'Share mail with chat team',
        image: 3
    }
]
    


class FeaturesChatSection extends React.Component{
    state = {
        currentButtonPlatform: 0,
        currentPlatform: desktop[0].image,
        activeIndex: 0,
        imagePos: 0
    };

    desktopCarousel(){
        let {imagesPrefix} = this.props;
        return(
            <div>
                <Carousel className="desktop" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={false} showIndicators={false} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                    <div >
                        <img src={`${imagesPrefix}/images/features-page/Blix- Chat-Teams-and-channels.webp`} alt="blix email on windows"/>
                    </div>
                    <div>
                        <img src={`${imagesPrefix}/images/features-page/Blix-Chat-Team-Messaging.webp`} alt="blix chat on windows"/>
                    </div>
                    <div>
                        <img src={`${imagesPrefix}/images/features-page/Blix-Chat-Rich-Messaging.webp`} alt="blix chat calendar on windows"/>
                    </div>
                    <div>
                        <img src={`${imagesPrefix}/images/features-page/Blix-Chat-Share-Mail-with-chat.webp`} alt="blix chat contacts on windows"/>
                    </div>
                </Carousel>
                <Carousel className="desktop-for-mobile-screen" autoPlay infiniteLoop showStatus={false} showIndicators={true} interval={5000} showArrows={true} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                    <div >
                        <img src={`${imagesPrefix}/images/features-page/Blix- Chat-Teams-and-channels-small.webp`} alt="blix email on windows"/>
                        <p style={{color: '#000', marginTop: '15px'}}>Create groups and subgroups</p>
                    </div>
                    <div>
                        <img src={`${imagesPrefix}/images/features-page/Blix-Chat-Team-Messaging-small.webp`} alt="blix chat on windows"/>
                        <p style={{color: '#000', marginTop: '15px'}}>Email your chat group directly</p>
                    </div>
                    <div>
                        <img src={`${imagesPrefix}/images/features-page/Blix-Chat-Rich-Messaging-small.webp`} alt="blix chat calendar on windows"/>
                        <p style={{color: '#000', marginTop: '15px'}}>Send/receive rich chat messages</p>
                    </div>
                    <div>
                        <img src={`${imagesPrefix}/images/features-page/Blix-Chat-Share-Mail-with-chat-small.webp`} alt="blix chat contacts on windows"/>
                        <p style={{color: '#000', marginTop: '15px'}}>Share email with chat group</p>
                    </div>
                </Carousel>
            </div>
            
            
        );
    }
    mobileCarousel(){
        let {imagesPrefix} = this.props;
        return(
            <div>
            <Carousel className="mobile" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={false} showIndicators={false} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                <div >
                    <img src={`${imagesPrefix}/Create groups and subgroups - 2 – 4.webp`}  alt="blix email on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Email your chat group directly - 2 – 5.webp`}  alt="blix chat on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Send-receive rich chat messages - 2 – 5.webp`}  alt="blix chat calendar on windows"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Share an email with chat - 2 – 4.webp`}  alt="blix chat contacts on windows"/>
                </div>
            </Carousel>
            <Carousel className="mobile-for-mobile-screen" autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={true} showIndicators={true} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => this.setState({activeIndex: e})}>
                <div >
                    <img src={`${imagesPrefix}/Create groups and subgroups - 2 – 4.webp`}  alt="blix email on windows"/>
                    <p style={{color: '#000'}}>Create groups and subgroups</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Email your chat group directly - 2 – 5.webp`}  alt="blix chat on windows"/>
                    <p style={{color: '#000'}}>Email your chat group directly</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Send-receive rich chat messages - 2 – 5.webp`}  alt="blix chat calendar on windows"/>
                    <p style={{color: '#000'}}>Send/receive rich chat messages</p>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Share an email with chat - 2 – 4.webp`}  alt="blix chat contacts on windows"/>
                    <p style={{color: '#000'}}>Share email with chat group</p>
                </div>
            </Carousel>
            </div>
        );
    }
    
    async toggleClass(index){
        await this.setState({activeIndex: index})
    };
    // async arrowHandler(direction){
    //     if(direction === 'right'){
    //         await this.setState({imagePos: this.imagePos+1});
    //     }
    //     await this.setState({imagePos: this.imagePos-1});
    // }

    render(){
        return (
            <div className="chat-features-section">
                <Container fixed className="container">
                    <Typography color='textPrimary' align="center" style={{fontSize: '38px', fontWeight: 'bold'}}>{data.title}</Typography>
                    <Typography color="textPrimary" align="center" variant="h6" style={{maxWidth: '1100px', margin: 'auto'}}>{data.subtext}</Typography>
                    <div className="buttons-wrapper" >
                        <button className={this.state.currentButtonPlatform === 0 ? 'active' : ''} onClick={async () => {await this.setState({currentButtonPlatform: 0}); await this.setState({currentPlatform: desktop})}}>Desktop</button>
                        <button className={this.state.currentButtonPlatform === 1 ? 'active' : ''} onClick={async() => {await this.setState({currentButtonPlatform: 1}); await this.setState({currentPlatform: mobile})}}>Mobile</button>
                    </div>
                    <Grid container direction="row" justify="flex-end" alignItems="center" style={{marginTop: '10px'}} spacing={5}>
                        
                        <Grid item md={9} sm={12} xs={12} className="grid-crousel-wrapper">
                            {/* <Carousel className="carousel-wrapper" showThumbs={false} showIndicators={false} showStatus={false}> */}
                                    <div>
                                    {this.state.currentButtonPlatform === 0 ? this.desktopCarousel() : 
                                        <div>
                                            
                                        {/* <img src="/FRAME.png" alt="blix - macbook pro" style={{maxWidth: '34%'}}/> */}
                                        <div>{this.mobileCarousel()}</div>
                                        </div>}
                                        
                                        
                                    {/* <img src="/blix-Desktop-Chat-Calendar-Windows.png" alt="blix - macbook pro"/> */}
                                    {/* <div style={{position: 'absolute', width: '66.6%', left: '18%', top: '4.7%'}}>{this.carouselimages()}</div> */}
                                    </div>
                            {/* </Carousel> */}
                        </Grid>
                        <Grid item md={3} sm={12} xs={12} className="side-btn-wrapper" style={{textAlign: 'center', display: 'grid'}}>
                            {desktop.map((item, i) => {
                                return(
                                    <Button key={i} className={this.state.activeIndex === i ? 'active' : ''} color="primary" onClick={() => {this.toggleClass(i)}}>{item.name}</Button>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
    
}

export default FeaturesChatSection;